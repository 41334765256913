<template>
  <div class="treeDiv" :style="{ height: th + 'px' }">
    <el-tree highlight-current ref="KTree" node-key="id" :data="knownLedgeList" v-show="type === 1" @node-click="getTopicByKnownLedge">
      <div slot-scope="{ node, data }" style="display: flex">
        <div>{{ data.name }}</div>
        <div style="color: #568FEB;margin-left: 8px">({{ data.questionCount || 0 }})</div>
      </div>
    </el-tree>
    <el-tree highlight-current ref="TTree" node-key="id" :data="tagList" v-show="type === 2" @node-click="getTopicByKnownLedge">
      <div slot-scope="{ node, data }" style="display: flex">
        <div>{{ data.name }}</div>
        <div style="color: #568FEB;margin-left: 8px">({{ data.questionCount || 0 }})</div>
      </div>
    </el-tree>
    <el-tree highlight-current ref="BTree" node-key="questionBankId" :data="bankList" v-show="type === 3" @node-click="getTopicByKnownLedge">
      <div slot-scope="{ node, data }" style="display: flex">
        <div>{{ data.name }}</div>
        <div style="color: #568FEB;margin-left: 8px">({{ data.questionCount || 0 }})</div>
      </div>
    </el-tree>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import question from '../../../../../components/common/old/questionOld'
export default {
  name: 'PaperType',
  components: { vuescroll },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      th: document.body.clientHeight - 220,
      initId1: '',
      initId2: '',
      initId3: '',
      selectIdBy1: '',
      selectIdBy2: '',
      selectIdBy3: '',
      knownLedgeList: [],
      tagList: [],
      bankList: [],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#333',
          opacity: 0.6 // 滚动条透明度
        }
      },
      backClass: '',
      colorBynodeId: 0,
      index: 0,
      colorSelectedBynodeId: 0,
      parent: 0,
      selectNodesIds: []
    }
  },
  created() {
    question.getKnownLedgeTree().then(res => {
      this.knownLedgeList = res.res
      this.initId1 = res.res[0].id || null
      this.$emit('questionBankId', this.initId1, true)
      this.$nextTick(() => {
        this.$refs.KTree.setCurrentKey(this.initId1)
      })
    })
    question.getTagTree().then(res => {
      this.initId2 = res.res[0].id || null
      this.tagList = res.res
      this.$nextTick(() => {
        this.$refs.TTree.setCurrentKey(this.initId2)
      })
    })
    question
      .getOpenQuestionBanksBymanual('', 0, 999, {
        forked: false,
        searchContent: ''
      })
      .then(res => {
        this.initId3 = res.res.data[0].questionBankId || null
        this.bankList = res.res.data
        this.$nextTick(() => {
          this.$refs.BTree.setCurrentKey(this.initId3)
        })
      })
  },
  watch: {
    type(val) {
      if (val === 1) {
        if (this.selectIdBy1) {
          this.$emit('questionBankId', this.selectIdBy1, true)
        } else {
          this.$emit('questionBankId', this.initId1, true)
        }
      } else if (val === 2) {
        if (this.selectIdBy2) {
          this.$emit('questionBankId', this.selectIdBy2, true)
        } else {
          this.$emit('questionBankId', this.initId2, true)
        }
      } else {
        if (this.selectIdBy3) {
          this.$emit('questionBankId', this.selectIdBy3, false)
        } else {
          this.$emit('questionBankId', this.initId3, false)
        }
      }
    }
  },
  methods: {
    getTopicByKnownLedge(data, node) {
      if (this.type === 1) {
        this.selectIdBy1 = data.id
        this.$emit('questionBankId', data.id, true)
      } else if (this.type === 2) {
        this.selectIdBy2 = data.id
        this.$emit('questionBankId', data.id, true)
      } else {
        this.selectIdBy3 = data.questionBankId
        this.$emit('questionBankId', data.questionBankId, false)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.treeDiv {
  text-align: left;
  margin: 10px 0 0 0;
  overflow: auto;
}
::v-deep .el-tree {
  .el-tree-node__children {
    overflow: visible !important;
  }
}
</style>
