<template>
  <!--  <vuescroll :ops="ops">-->
  <div class="type">
    <vuescroll :ops="ops">
      <Collapse v-if="section.questionVOS.length !== 0" simple v-for="(section, qIndex) in selectedQuestionList" :key="'section-' + qIndex">
        <Panel style="position: relative">
          <span>{{ section.name }}</span>
          <div style="display: flex;align-items: center;position: absolute;right: 0">
            <span style="margin-right: 15px">{{ section.questionVOS.length }}</span>
            <div class="flexAliCenter" @mouseenter="IsdeleByIndex = 'DeleAll+' + qIndex" @mouseleave="IsdeleByIndex = ''">
              <Tooltip :content="'删除所有' + section.name" placement="left">
                <JYImg
                  v-if="IsdeleByIndex !== 'DeleAll+' + qIndex"
                  :Url="require('../../../../../assets/delete.png')"
                  @click="handleRemoveSection(qIndex)"
                />
                <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="handleRemoveSection(qIndex)" />
              </Tooltip>
            </div>
          </div>
          <div class="topicExpand" slot="content" v-for="(question, index) in section.questionVOS" :key="'question-' + index">
            <span class="stemSpan">{{ index + 1 }}、<span :title="removeHtml(question.stem)" v-html="removeHtml(question.stem)"/></span>
            <div class="flexAliCenter" @mouseenter="IsdeleByIndex = 'deleAll+' + qIndex + '' + index" @mouseleave="IsdeleByIndex = ''">
              <!--              <Tooltip content="删除" placement="top">-->
              <JYImg
                v-if="IsdeleByIndex !== 'deleAll+' + qIndex + '' + index"
                :Url="require('../../../../../assets/delete.png')"
                @click="delQuestion(index, qIndex)"
              />
              <JYImg v-else :Url="require('../../../../../assets/deleteH.png')" @click="delQuestion(index, qIndex)" />
              <!--              </Tooltip>-->
            </div>
          </div>
        </Panel>
      </Collapse>
    </vuescroll>
  </div>
  <!--  </vuescroll>-->
</template>

<script>
import vuescroll from 'vuescroll'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { questionType } from '@/util/questionType'
import JYImg from '../../../../common/JYImg'

export default {
  name: 'PaperByQuestionType',
  components: { vuescroll, JYImg },
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#333',
          opacity: 0.6 // 滚动条透明度
        }
      },
      topicByQuestionType: [],
      multipchoice: [],
      judgeTrueOrFalse: [],
      fillBlanks: [],
      MulMoreChoice: [],
      shortList: [],
      questionList: this.list,
      IsdeleByIndex: ''
    }
  },
  computed: {
    ...mapState('question', ['paperTitle']),
    ...mapGetters('question', ['getEditorData']),
    canCancel: {
      get() {
        return this.getEditorData.isClassify
      },
      set(val) {
        this.setEditorData({
          isClassify: val
        })
      }
    },
    selectedQuestionList: {
      get() {
        return this.$store.state.question.selectedQuestionList
      },
      set(newVal) {
        this.$store.commit('question/setSelectedQuestionList', newVal)
      }
    }
  },
  methods: {
    ...mapMutations('question', ['deleteSection', 'setPaperTitle', 'setSectionName', 'setEditorData']),
    ...mapActions('question', ['loadEditPaperDetail']),
    classify() {
      let map = {}
      let dest = []
      for (let i = 0; i < this.selectedQuestionList[0].questionVOS.length; i++) {
        let ai = this.selectedQuestionList[0].questionVOS[i]
        if (!map[ai.questionType]) {
          dest.push({
            name: questionType[ai.questionType].label,
            questionVOS: [ai]
          })
          map[ai.questionType] = ai
        } else {
          for (let j = 0; j < dest.length; j++) {
            let dj = dest[j]
            if (dj.name === questionType[ai.questionType].label) {
              dj.questionVOS.push(ai)
              break
            }
          }
        }
      }
      let compare = (x, y) => {
        let val1 = x.questionVOS[0].questionType
        let val2 = y.questionVOS[0].questionType
        if (val1 < val2) {
          return -1
        } else if (val1 > val2) {
          return 1
        } else {
          return 0
        }
      }
      let all = dest.sort(compare)
      all.map(item => {
        this.addClassification(item.name, item.questionVOS)
      })
      let arr = this.selectedQuestionList.slice()
      arr.splice(0, 1)
      this.selectedQuestionList = arr
    },
    addClassification(name, questions) {
      const newSectionList = this.selectedQuestionList.concat({
        name,
        questions
      })
      this.selectedQuestionList = newSectionList
      this.resetTotalScore()
      this.canCancel = true
    },
    resetTotalScore() {
      this.selectedQuestionList.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
    },
    removeHtml(input) {
      let stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
      let output = input.replace(stringStripper, ' ')
      // 2. strip Word generated HTML comments
      let commentSripper = new RegExp('<!--(.*?)-->', 'g')
      output = output.replace(commentSripper, '')
      let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi')
      // 3. remove tags leave content if any
      output = output.replace(tagStripper, '')
      // 4. Remove everything in between and including tags '<style(.)style(.)>'
      let badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']

      for (let i = 0; i < badTags.length; i++) {
        tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi')
        output = output.replace(tagStripper, '')
      }
      // 5. remove attributes ' style="..."'
      let badAttributes = ['style', 'start']
      for (let i = 0; i < badAttributes.length; i++) {
        let attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi')
        output = output.replace(attributeStripper, '')
      }
      return output.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')
    },
    handleRemoveSection(index) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除该大题和该大题下的所有题目吗？</p>',
        onOk: () => {
          this.deleteSection(index)
        }
      })
    },
    delQuestion(index, qIndex) {
      let a = JSON.parse(JSON.stringify(this.selectedQuestionList))
      a[qIndex].questionVOS.splice(index, 1)
      a.map(item => {
        let s = 0
        item.questionVOS.map(q => {
          s += q.points
        })
        item.totalPoints = s
      })
      this.selectedQuestionList = a
    }
  }
}
</script>

<style lang="less" scoped>
.flexAliCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
::v-deep .ivu-tooltip-rel {
  display: flex;
}
.type {
  width: 100%;
  height: 100%;
  /*max-height: calc(100% - 80px);*/
  .iconStyle {
    font-size: 17px;
    cursor: pointer;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .stemSpan {
    width: 245px;
    display: flex;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .topicExpand {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    /*height: 25px;*/
  }
}
::v-deep .ivu-collapse-content {
  padding: 0 0 16px;
}
/deep/ .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 55px;
  display: flex;
  align-items: center;
}
</style>
